import __request__ from 'routing/request';
interface JsonNode {
}
interface SessionValidationResponse {
    readonly is_valid: boolean;
    readonly session_id: string;
    readonly username: string;
}
interface SessionResponse {
}
/**
 * Terminate an existing session
 */
export function terminateSession(): Promise<unknown> {
    return __request__('DELETE', '/system/sessions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Validate an existing session
 */
export function validateSession(): Promise<SessionValidationResponse> {
    return __request__('GET', '/system/sessions', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new session
 * @param Loginrequest Credentials. The default implementation requires presence of two properties: 'username' and 'password'. However a plugin may customize which kind of credentials are accepted and therefore expect different properties.
 */
export function newSession(Loginrequest: JsonNode): Promise<SessionResponse> {
    return __request__('POST', '/system/sessions', Loginrequest, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Terminate an existing session
 */
export function terminateSessionWithId(sessionId: string): Promise<unknown> {
    return __request__('DELETE', `/system/sessions/${sessionId}`, null, {}, {
        'Accept': ['application/json']
    });
}
