import __request__ from 'routing/request';
interface JvmStats {
    readonly spec_version: string;
    readonly vm_vendor: string;
    readonly spec_vendor: string;
    readonly version: string;
    readonly start_time: number;
    readonly vm_version: string;
    readonly system_properties: {
        readonly [_key: string]: string;
    };
    readonly mem: Memory;
    readonly spec_name: string;
    readonly memory_pools: string[];
    readonly input_arguments: string[];
    readonly class_path: string;
    readonly garbage_collectors: string[];
    readonly vm_name: string;
    readonly boot_class_path: string;
}
interface ProcessStats {
    readonly memory: Memory;
    readonly open_file_descriptors: number;
    readonly max_file_descriptors: number;
    readonly cpu: Cpu;
    readonly pid: number;
}
interface Processor {
    readonly total_sockets: number;
    readonly stolen: number;
    readonly cache_size: number;
    readonly idle: number;
    readonly vendor: string;
    readonly total_cores: number;
    readonly model: string;
    readonly cores_per_socket: number;
    readonly sys: number;
    readonly user: number;
    readonly mhz: number;
}
interface Memory {
    readonly total_virtual: number;
    readonly share: number;
    readonly resident: number;
}
interface NetworkStats {
    readonly tcp: TcpStats;
    readonly interfaces: {
        readonly [_key: string]: Interface;
    };
    readonly primary_interface: string;
}
interface Cpu {
    readonly total: number;
    readonly sys: number;
    readonly percent: number;
    readonly user: number;
}
interface OsStats {
    readonly load_average: number[];
    readonly memory: Memory;
    readonly swap: Swap;
    readonly processor: Processor;
    readonly uptime: number;
}
interface FsStats {
    readonly filesystems: {
        readonly [_key: string]: Filesystem;
    };
}
interface TcpStats {
    readonly out_segs: number;
    readonly curr_estab: number;
    readonly attempt_fails: number;
    readonly estab_resets: number;
    readonly in_errs: number;
    readonly active_opens: number;
    readonly passive_opens: number;
    readonly in_segs: number;
    readonly retrans_segs: number;
    readonly out_rsts: number;
}
interface SystemStats {
    readonly jvm: JvmStats;
    readonly process: ProcessStats;
    readonly os: OsStats;
    readonly fs: FsStats;
    readonly network: NetworkStats;
}
interface Swap {
    readonly total: number;
    readonly used: number;
    readonly free: number;
}
interface Filesystem {
    readonly disk_queue: number;
    readonly type_name: string;
    readonly inodes_used_percent: number;
    readonly inodes_total: number;
    readonly available: number;
    readonly used: number;
    readonly mount: string;
    readonly disk_reads: number;
    readonly path: string;
    readonly disk_service_time: number;
    readonly total: number;
    readonly disk_read_bytes: number;
    readonly dev: string;
    readonly inodes_used: number;
    readonly inodes_free: number;
    readonly used_percent: number;
    readonly sys_type_name: string;
    readonly free: number;
    readonly disk_write_bytes: number;
    readonly disk_writes: number;
}
interface Interface {
    readonly addresses: string[];
    readonly interface_stats: InterfaceStats;
    readonly mac_address: string;
    readonly name: string;
    readonly mtu: number;
}
interface InterfaceStats {
    readonly tx_packets: number;
    readonly rx_dropped: number;
    readonly tx_carrier: number;
    readonly rx_packets: number;
    readonly rx_errors: number;
    readonly tx_errors: number;
    readonly rx_overruns: number;
    readonly rx_frame: number;
    readonly tx_collisions: number;
    readonly tx_dropped: number;
    readonly rx_bytes: number;
    readonly tx_overruns: number;
    readonly tx_bytes: number;
}
/**
 * System information about this node.
 */
export function systemStats(): Promise<SystemStats> {
    return __request__('GET', '/system/stats', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Filesystem information about this node.
 */
export function fsStats(): Promise<FsStats> {
    return __request__('GET', '/system/stats/fs', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * JVM information about this node.
 */
export function jvmStats(): Promise<JvmStats> {
    return __request__('GET', '/system/stats/jvm', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Networking information about this node.
 */
export function networkStats(): Promise<NetworkStats> {
    return __request__('GET', '/system/stats/network', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * OS information about this node.
 */
export function osStats(): Promise<OsStats> {
    return __request__('GET', '/system/stats/os', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Process information about this node.
 */
export function processStats(): Promise<ProcessStats> {
    return __request__('GET', '/system/stats/process', null, {}, {
        'Accept': ['application/json']
    });
}
