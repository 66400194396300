import __request__ from 'routing/request';
interface RetentionStrategyConfig {
    readonly max_number_of_indices: number;
    readonly type: string;
}
interface DataTieringConfig {
    readonly index_lifetime_min: string;
    readonly index_lifetime_max: string;
    readonly type: string;
}
interface RotationStrategyConfig {
    readonly type: string;
}
interface IndexSetsDefaultConfiguration {
    readonly index_optimization_max_num_segments: number;
    readonly replicas: number;
    readonly field_type_refresh_interval: number;
    readonly retention_strategy_config: RetentionStrategyConfig;
    readonly index_optimization_disabled: boolean;
    readonly retention_strategy_class: string;
    readonly retention_strategy: RetentionStrategyConfig;
    readonly shards: number;
    readonly field_type_refresh_interval_unit: 'NANOSECONDS' | 'MICROSECONDS' | 'MILLISECONDS' | 'SECONDS' | 'MINUTES' | 'HOURS' | 'DAYS';
    readonly index_analyzer: string;
    readonly rotation_strategy: RotationStrategyConfig;
    readonly use_legacy_rotation: boolean;
    readonly rotation_strategy_class: string;
    readonly rotation_strategy_config: RotationStrategyConfig;
    readonly data_tiering: DataTieringConfig;
}
/**
 * Update index set defaults configuration
 * @param body The payload of the index set defaults configuration
 */
export function update(body: IndexSetsDefaultConfiguration): Promise<unknown> {
    return __request__('PUT', '/system/indices/index_set_defaults', body, {}, {
        'Accept': ['application/json']
    });
}
