import __request__ from 'routing/request';
/**
 * Retrieve the status of an executed query
 */
export function asyncSearchJobStatus(jobId?: string, nodeId?: string): Promise<void> {
    return __request__('GET', `/views/searchjobs/${nodeId}/${jobId}/status`, null, {}, {
        'Accept': [
            'application/json',
            'application/vnd.graylog.search.v1+json'
        ]
    });
}
